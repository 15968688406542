/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from 'react';
import styled from 'styled-components';
import Logo from '../../assets/svg/logo.svg';

const PristinePolishLogoContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;

  .companyLogo {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
  }

  .companyText {
    font-family: 'IBM Plex Serif', serif;
    font-weight: bold;
    font-size: 1em;
    color: var(--text-color);
    padding: 0;
    margin: 0;
  }
`;

const PristinePolishLogo = () => (
  <PristinePolishLogoContainer>
    <Logo className="companyLogo" />
    <p className="companyText">Pristine Polish</p>
  </PristinePolishLogoContainer>
);

export default PristinePolishLogo;
